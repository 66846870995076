import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "../../../assets/css/styles/collection.css";

import collect1 from "../../../assets/images/dress/collection01.jpg";
import collect2 from "../../../assets/images/dress/collection03.jpg";

export default function Collection() {

    return (
        <div id="sarv--collection" className="collection">
            <div className="collection-box">
                <Row>
                    <Col md={4} xs={12}>
                        <Row>
                            <Col>
                                <div className="collection-item1">
                                    <div className="collection-content">
                                        <Link to="/collections">
                                            <img className="image1" src={collect1} alt="fall callection"/>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={7} xs={12}>
                        <Row>
                            <Col>
                                <div className="collection-item2">
                                    <div className="collection-content">
                                        <Link to="/collections">
                                            <img className="image2" src={collect2} alt="fall callection"/>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}