import React, {useState, useEffect} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactPlayer from 'react-player/file';
import Slide from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../../../assets/css/styles/slider.css";
import "../../../../assets/css/styles/collection.css";
import 'react-tabs/style/react-tabs.css';
import "../../../../assets/css/styles/tab.css";
import "../../../../assets/css/styles/video.css";

import Look1 from "../../../../assets/images/collections/spring/spring2023/look1.jpg";
import Look1A from "../../../../assets/images/collections/spring/spring2023/look1A.jpg";
import Look1B from "../../../../assets/images/collections/spring/spring2023/look1B.jpg";
import Look1C from "../../../../assets/images/collections/spring/spring2023/look1C.jpg";
import Look2 from "../../../../assets/images/collections/spring/spring2023/look2.jpg";
import Look2A from "../../../../assets/images/collections/spring/spring2023/look2A.jpg";
import Look2B from "../../../../assets/images/collections/spring/spring2023/look2B.jpg";
import Look2C from "../../../../assets/images/collections/spring/spring2023/look2C.jpg";
import Look2D from "../../../../assets/images/collections/spring/spring2023/look2D.jpg";
import Look2E from "../../../../assets/images/collections/spring/spring2023/look2E.jpg";
import Look3 from "../../../../assets/images/collections/spring/spring2023/look3.jpg";
import Look3A from "../../../../assets/images/collections/spring/spring2023/look3A.jpg";
import Look3B from "../../../../assets/images/collections/spring/spring2023/look3B.jpg";
import Look3C from "../../../../assets/images/collections/spring/spring2023/look3C.jpg";
import Look3D from "../../../../assets/images/collections/spring/spring2023/look3D.jpg";
import Look4 from "../../../../assets/images/collections/spring/spring2023/look4.jpg";
import Look4A from "../../../../assets/images/collections/spring/spring2023/look4A.jpg";
import Look4B from "../../../../assets/images/collections/spring/spring2023/look4B.jpg";
import Look4C from "../../../../assets/images/collections/spring/spring2023/look4C.jpg";
import Look4D from "../../../../assets/images/collections/spring/spring2023/look4D.jpg";
import Look5 from "../../../../assets/images/collections/spring/spring2023/look5.jpg";
import Look5A from "../../../../assets/images/collections/spring/spring2023/look5A.jpg";
import Look5B from "../../../../assets/images/collections/spring/spring2023/look5B.jpg";
import Look5C from "../../../../assets/images/collections/spring/spring2023/look5C.jpg";
import Look5D from "../../../../assets/images/collections/spring/spring2023/look5D.jpg";
import Look6 from "../../../../assets/images/collections/spring/spring2023/look6.jpg";
import Look6A from "../../../../assets/images/collections/spring/spring2023/look6A.jpg";
import Look6B from "../../../../assets/images/collections/spring/spring2023/look6B.jpg";
import Look6C from "../../../../assets/images/collections/spring/spring2023/look6C.jpg";
import Look6D from "../../../../assets/images/collections/spring/spring2023/look6D.jpg";


import M1 from "../../../../assets/images/collections/mdboard/spring/spring2023/1.jpg";
import M2 from "../../../../assets/images/collections/mdboard/spring/spring2023/2.jpg";
import M3 from "../../../../assets/images/collections/mdboard/spring/spring2023/3.jpg";
import M4 from "../../../../assets/images/collections/mdboard/spring/spring2023/4.jpg";
import M5 from "../../../../assets/images/collections/mdboard/spring/spring2023/5.jpg";
import M6 from "../../../../assets/images/collections/mdboard/spring/spring2023/6.jpg";
import M7 from "../../../../assets/images/collections/mdboard/spring/spring2023/7.jpg";
import M8 from "../../../../assets/images/collections/mdboard/spring/spring2023/8.jpg";
import M10 from "../../../../assets/images/collections/mdboard/spring/spring2023/10.jpg";
import M11 from "../../../../assets/images/collections/mdboard/spring/spring2023/11.jpg";
import M12 from "../../../../assets/images/collections/mdboard/spring/spring2023/12.jpg";
import M13 from "../../../../assets/images/collections/mdboard/spring/spring2023/13.jpg";
import M14 from "../../../../assets/images/collections/mdboard/spring/spring2023/14.jpg";
import M15 from "../../../../assets/images/collections/mdboard/spring/spring2023/15.jpg";

export default function Spring2023() {
    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        fade: true,
        autoplay: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    return (
        <>
            <Container>
                <Row>
                    <div className="collection-category-content">
                        <div className="collection-cate-box">
                            <Row>
                                <Col md={12}>
                                    <div className="collection__tab">
                                        <Tabs>
                                            <TabList>
                                                <Tab>statement</Tab>
                                                <Tab>images</Tab>
                                            </TabList>

                                            <TabPanel>
                                                <Container>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="collection--statement--box">
                                                                <p>
                                                                    The collection “The Charm of Abgineh”; meaning
                                                                    ‘Mirror Magic’, tells the story of a movement which
                                                                    took place in Iran in late September 2022. With the
                                                                    continuation of this movement and its spread, it
                                                                    became known as the “Women, Life, and Liberty”
                                                                    revolution all over the world. This collection
                                                                    includes six sets of coats on which motifs inspired
                                                                    by this revolution have been executed. Most of these
                                                                    motifs are executed with decorative mirrors; a
                                                                    mirror is a symbol of power and truth, a tool that
                                                                    reflects the truth, as the voice of the Iranian
                                                                    people was powerful and reflected the truth of the
                                                                    story. Symbolizing the events and prominent people
                                                                    of the revolution “Women, Life, and Liberty” is the
                                                                    most characteristic of concept of this collection.
                                                                    Each jacket has its unique name, (the Song of the
                                                                    Phoenix, Gina, Woman, Kian, Liberty, and Khodanoor)
                                                                    its name and the personal signature of the designer
                                                                    has been engraved with the help of embroidery.

                                                                </p>
                                                                <p>
                                                                    The goal of the designer is to convey the message of
                                                                    “Women, Life, and Liberty” with conceptual elements,
                                                                    while maintaining the identity of the brand. The
                                                                    interesting part of this collection is the symbolic
                                                                    story line written by the designer to draw the
                                                                    audience’s attention to the concept of the works and
                                                                    connect their souls to each other.
                                                                </p>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div
                                                                className="collection--statement--mood spring--collection">
                                                                <h5 className="mood--board--title">Mood Board</h5>
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={M1}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M2}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M3}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M4}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M5}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M6}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M7}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M8}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M10}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M11}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M12}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M13}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M14}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M15}/>
                                                                    </div>
                                                                </Slide>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <div
                                                                className="collection--statement--box statement--second--box">
                                                                <p>
                                                                    In the land where
                                                                    a mirror with magical power protected its people, it
                                                                    is broken and scattered by demons. The works begin
                                                                    with a design of a phoenix, which is a symbol of the
                                                                    Iranian people. While a mythological character
                                                                    called ‘Life\Zendegi’ (the 2nd Work/Gina) dies, the
                                                                    phoenix catches fire and a young phoenix is born and
                                                                    rises. While the phoenix tries to find the pieces of
                                                                    the mirror and then connect them together, contrary
                                                                    to previous legends, the tears of the phoenix have
                                                                    no effect on joining and healing the mirror.
                                                                </p>
                                                                <p>
                                                                    In the continuation of the story, the character of
                                                                    the ‘Woman’ (the 3rd Work), the character of ‘Kian’
                                                                    (the 4th Work), and the character of Khodanoor (the
                                                                    5th Work) come to the help of the Phoenix and each
                                                                    of them plays their roles in connecting the mirror.
                                                                    And finally, the unity of each and every inhabitant
                                                                    of the mirror land with the Phoenix, ‘Liberty\Azadi’
                                                                    (the name of the sixth Work) happens.
                                                                </p>
                                                                <p>
                                                                    What is exhibited in this collection is the unity of
                                                                    the Iranian people in another narrative. And what
                                                                    ‘Sarv’, the designer, always follows is giving souls
                                                                    to clothes and telling stories so that people can
                                                                    change their looks at clothes and do not see them
                                                                    merely a simple fabric to wear, rather a dress that
                                                                    wants to be more effective.
                                                                </p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="collection--video">
                                                    <Row className="justify-content-md-center">
                                                        <Col md={12}>
                                                            <div className="video--content">
                                                                <div className='player-wrapper'>
                                                                    <ReactPlayer
                                                                        className='react-player'
                                                                        url={"video/Spring-Summer-2023-2.mp4"}
                                                                        width='90%'
                                                                        height='90%'
                                                                        controls={true}
                                                                        muted={true}
                                                                        playing={false}
                                                                        loop={false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="collection--inner--cate spring--inner--slider">
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look1}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look1A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look1B}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look1C}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look1</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>
                                                                    <div>
                                                                        <img src={Look2}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look2A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look2B}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look2C}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look2D}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look2E}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look2</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>
                                                                    <div>
                                                                        <img src={Look3}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look3A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look3B}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look3C}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look3D}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look3</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>
                                                                    <div>
                                                                        <img src={Look4}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look4A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look4B}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look4C}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look4D}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look4</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>
                                                                    <div>
                                                                        <img src={Look5}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look5A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look5B}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look5C}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look5D}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look5</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>
                                                                    <div>
                                                                        <img src={Look6}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look6A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look6B}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look6C}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look6D}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look6</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    )
}