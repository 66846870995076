import React, {useState, useEffect} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactPlayer from 'react-player/file';
import Slide from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../../../assets/css/styles/slider.css";
import "../../../../assets/css/styles/collection.css";
import 'react-tabs/style/react-tabs.css';
import "../../../../assets/css/styles/tab.css";
import "../../../../assets/css/styles/video.css";

import Look1A from "../../../../assets/images/collections/fall/look1A.jpg";
import Look1B from "../../../../assets/images/collections/fall/look1B.jpg";
import Look2A from "../../../../assets/images/collections/fall/look2A.jpg";
import Look2B from "../../../../assets/images/collections/fall/look2B.jpg";
import Look3A from "../../../../assets/images/collections/fall/look3A.jpg";
import Look3B from "../../../../assets/images/collections/fall/look3B.jpg";
import Look4A from "../../../../assets/images/collections/fall/look4A.jpg";
import Look4B from "../../../../assets/images/collections/fall/look4B.jpg";
import Look5A from "../../../../assets/images/collections/fall/look5A.jpg";
import Look5B from "../../../../assets/images/collections/fall/look5B.jpg";
import Look6A from "../../../../assets/images/collections/fall/look6A.jpg";
import Look6B from "../../../../assets/images/collections/fall/look6B.jpg";
import Look7 from "../../../../assets/images/collections/fall/look7.jpg";
import Look8A from "../../../../assets/images/collections/fall/look8A.jpg";
import Look8B from "../../../../assets/images/collections/fall/look8B.jpg";
import Look9A from "../../../../assets/images/collections/fall/look9A.jpg";
import Look9B from "../../../../assets/images/collections/fall/look9B.jpg";
import Look10 from "../../../../assets/images/collections/fall/look10.jpg";

import M1 from "../../../../assets/images/collections/mdboard/fall/1.jpg";
import M2 from "../../../../assets/images/collections/mdboard/fall/2.jpg";
import M3 from "../../../../assets/images/collections/mdboard/fall/3.jpg";
import M4 from "../../../../assets/images/collections/mdboard/fall/3b.jpg";
import M5 from "../../../../assets/images/collections/mdboard/fall/4.jpg";
import M6 from "../../../../assets/images/collections/mdboard/fall/5.jpg";
import M7 from "../../../../assets/images/collections/mdboard/fall/6.jpg";
import M8 from "../../../../assets/images/collections/mdboard/fall/7.jpg";
import M9 from "../../../../assets/images/collections/mdboard/fall/8.jpg";
import M10 from "../../../../assets/images/collections/mdboard/fall/9.jpg";
import M11 from "../../../../assets/images/collections/mdboard/fall/10.jpg";
import M12 from "../../../../assets/images/collections/mdboard/fall/11.jpg";

export default function Fall() {

    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        fade: true,
        autoplay: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    return (
        <>
            <Container>
                <Row>
                    <div className="collection-category-content">
                        <div className="collection-cate-box">
                            <Row>
                                <Col md={12}>
                                    <div className="collection__tab">
                                        <Tabs>
                                            <TabList>
                                                <Tab>statement</Tab>
                                                <Tab>images</Tab>
                                            </TabList>

                                            <TabPanel>
                                                <Container>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="collection--statement--box">
                                                                <p>This collection is called “Mah TT”, a Mazandarani
                                                                    word meaning the blossom of the moon. Mazandaran is
                                                                    a province in the north of Iran and neighboring the
                                                                    Caspian Sea with a rich and colorful culture. The
                                                                    designer creates this collection inspired by the
                                                                    clothes of Mazandarini women about 50-60 years ago.
                                                                    She searches through old photos. The people of
                                                                    Mazandaran and different tribes of Iran used to wear
                                                                    colorful and happy traditional clothes in the past.
                                                                    Therefore, weaving fabric in different traditional
                                                                    ways has a long history and with the advancement of
                                                                    technology, industrial factories were also used.
                                                                    Mazandaran was also one of the leading provinces of
                                                                    Iran in the production of both traditional and
                                                                    industrial fabrics. However, the unfavorable
                                                                    economic conditions, sanctions and lack of support
                                                                    and management by responsible managers caused the
                                                                    closure of many industrial factories and the
                                                                    forgetting of traditional fabric weaving.
                                                                    One of the interesting parts of this collection is
                                                                    hand woven fabrics. These fabrics were inspired by
                                                                    Chador Shoo by an old lady, who lived in a village
                                                                    in the mountains, with the help of an old
                                                                    traditional machine. Chador Shoo was one of the
                                                                    important elements of Mazandarni women’s clothing,
                                                                    which they tied around their waists to keep
                                                                    themselves warm. This piece was finally woven with
                                                                    the help of traditional weaving machines during a
                                                                    time-consuming and complicated process.
                                                                </p>

                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="collection--statement--mood">
                                                                <h5 className="mood--board--title">Mood Board</h5>
                                                                <Slide {...settings}>
                                                                    <div>
                                                                        <img src={M1}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M2}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M3}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M4}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M5}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M6}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M7}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M8}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M9}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M10}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M11}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M12}/>
                                                                    </div>
                                                                </Slide>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <div
                                                                className="collection--statement--box statement--second--box">
                                                                <p>
                                                                    Nowadays, Chador Shoo is woven as a blanket by
                                                                    industrial machines. Inspired by Chador Shoo, the
                                                                    designer designed and produced fabrics different
                                                                    from previous generation. In addition to that vest,
                                                                    the skirt and coat were also one of the other
                                                                    clothing worn by Mazandarani women which had an
                                                                    impact on this collection.
                                                                    Another interesting thing is the production of
                                                                    custom buttons made of walnut wood with brand logo
                                                                    engraving to induce a sense of antiquity and
                                                                    originality. The goal of the designer is to create a
                                                                    connection between the old and the new generation
                                                                    and to commemorate the past generation by using old
                                                                    texture techniques and forgotten coatings.
                                                                </p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="collection--video">
                                                    <Row className="justify-content-md-center">
                                                        <Col md={12}>
                                                            <div className="video--content">
                                                                <div className='player-wrapper'>
                                                                    <ReactPlayer
                                                                        className='react-player'
                                                                        url="video/Fall-Winter-2021-2022.mp4"
                                                                        width='90%'
                                                                        height='90%'
                                                                        controls={true}
                                                                        muted={true}
                                                                        playing={false}
                                                                        loop={false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="collection--inner--cate">
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look1A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look1B}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look1</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look2A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look2B}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look2</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look3A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look3B}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look3</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look4A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look4B}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look4</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look5A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look5B}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look5</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look6A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look6B}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look6</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look7}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look7</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look8A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look8B}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look8</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look9A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look9B}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look9</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>
                                                                    <div>
                                                                        <img src={Look10}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look10</h5>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </div>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    )
}