import React from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { faEnvelope,faPaperPlane} from "@fortawesome/free-regular-svg-icons";
import { faInstagram} from "@fortawesome/free-brands-svg-icons";
import { TbBrandLinkedin,TbBrandTelegram,TbBrandInstagram,TbBrandTwitter } from "react-icons/tb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../../assets/css/styles/footer.css";

export default function Footer() {

    return (
        <>
            <Container id="sarv--contact-us" fluid id="footer--box">
                <div className="sarv--footer">
                    <Row className="justify-content-md-center">
                        <Col md={12}>
                            <div className="footer--content">
                                <ul className="footer--menu">
                                    <li className="footer--item">
                                        <a href="https://twitter.com/sarv_vras" target="_blank" className="footer-links">
                                            <TbBrandTwitter/>
                                        </a>
                                    </li>
                                    <li className="footer--item">
                                        <a href="https://www.linkedin.com/company/sarv-fashionhouse/" target="_blank" className="footer-links">
                                            <TbBrandLinkedin/>
                                        </a>
                                    </li>
                                    <li className="footer--item">
                                        <a href="https://www.instagram.com/sarv.fashionhouse" target="_blank" className="footer-links">
                                            <TbBrandInstagram/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="footer--copyright">
                    <Row>
                        <Col md={12}>
                            <div>
                                <p>&copy; All Rights Reserved for Sarv</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}