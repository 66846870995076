import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {Link} from "react-router-dom";

import {faBars} from "@fortawesome/free-solid-svg-icons";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import logo from "../../assets/images/logo.png";

import "../../assets/css/styles/nav.css"

export default function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [navBar, setNavBar] = useState(false);

    const [changeRoute, setChangeRoute] = useState(false);

    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);

    const location = useLocation();

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener("resize", showButton);

    const changeColor = () => {
        if (window.scrollY >= 600) {
            setNavBar(true)
        } else {
            setNavBar(false)
        }
    }
    window.addEventListener('scroll', changeColor);

    useEffect(() => {
        if (window.location.pathname !== "/") {
            setChangeRoute(true);
        }
    }, [location]);

    return (
        <>
            <nav className={navBar ? 'navbar active' : 'navbar'}>
                {/*<div className="navbar-container">*/}
                <div className={changeRoute ? "navbar-container active--color" : "navbar-container"}>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <Link to="/biography" className="nav-links" onClick={closeMobileMenu}>
                                Biography
                            </Link>
                        </li>
                        <li className="nav-item">
                            <a href="/#sarv--shop" className="nav-links" >
                                Shop
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/" onClick={closeMobileMenu}>
                                <img src={logo} alt="sarv" className="navbar-logo" onClick={closeMobileMenu}/>
                            </a>
                            <div className="menu-icon" onClick={handleClick}>
                                {click ? <FontAwesomeIcon icon={faTimes}/> : <FontAwesomeIcon icon={faBars}/>}
                            </div>
                        </li>
                        <li className="nav-item">
                            <a href="/#sarv--collection" className="nav-links" onClick={closeMobileMenu}>
                                Collection
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/#footer--box" className="nav-links" onClick={closeMobileMenu}>
                                Contact us
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )

}