import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "../../../assets/css/styles/collection.css";

import collect1 from "../../../assets/images/collections/fall-collection.jpg";
import collect2 from "../../../assets/images/collections/spring-collection.jpg";
import collect3 from "../../../assets/images/collections/spring-collection2023.jpg";

export default function Collection() {

    return (
        <>
            <Container>
                <Row>
                    <div className="collection-category">
                        <div className="collection-cate-box">
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col md={8}>
                                            <div className="collection-spring collection-spring-b">
                                                <Row>
                                                    <Col md={4}>
                                                        <div className="collection-spring-img">
                                                            <img className="" src={collect3} alt="spring-collection2023"/>
                                                        </div>
                                                    </Col>
                                                    <Col md={8}>
                                                        <div className="collection-spring-text">
                                                            <h5>
                                                                spring/summer
                                                            </h5>
                                                            <h5>
                                                                2023
                                                            </h5>
                                                            <h5>
                                                                ready-to-wear
                                                            </h5>
                                                        </div>
                                                        <div className="collection-link">
                                                            <Link to="/spring2023">
                                                                discover more >
                                                            </Link>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col md={4}>
                                        </Col>
                                        <Col md={8}>
                                            <div className="collection-spring old-spring">
                                                <Row>
                                                    <Col md={8}>
                                                        <div className="collection-spring-text">
                                                            <h5>
                                                                spring/summer
                                                            </h5>
                                                            <h5>
                                                                2022
                                                            </h5>
                                                            <h5>
                                                                ready-to-wear
                                                            </h5>
                                                        </div>
                                                        <div className="collection-link">
                                                            <Link to="/spring">
                                                                discover more >
                                                            </Link>
                                                        </div>
                                                    </Col>

                                                    <Col md={4}>
                                                        <div className="collection-spring-img">
                                                            <img className="" src={collect2} alt="spring-collection"/>
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Row>

                                        <Col md={8}>
                                            <div className="collection-winter collection-spring-t old-winter">
                                                <Row>
                                                    <Col md={4}>
                                                        <div className="collection-winter-img">
                                                            <img className="" src={collect1} alt="fall-collection"/>
                                                        </div>
                                                    </Col>
                                                    <Col md={8}>
                                                        <div className="collection-winter-text">
                                                            <h5>
                                                                fall/winter
                                                            </h5>
                                                            <h5>
                                                                2021-2022
                                                            </h5>
                                                            <h5>
                                                                ready-to-wear
                                                            </h5>
                                                        </div>
                                                        <div className="collection-link">
                                                            <Link to="/fall-winter">
                                                                discover more >
                                                            </Link>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    )
}