import React, {useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "../../../assets/css/styles/shop.css";

import dress1 from "../../../assets/images/dress/d08.jpg";
import dress2 from "../../../assets/images/dress/d07.jpg";
import dress3 from "../../../assets/images/dress/d01.jpg";
import dress4 from "../../../assets/images/dress/d02.jpg";
import dress5 from "../../../assets/images/dress/d04.jpg";
import dress6 from "../../../assets/images/dress/d05.jpg";


export default function Shop() {

    return (
        <Container id='sarv--shop'>
            <Row>
                <Col md={12} className="box--shop">
                    <Row>
                        <Col md={12}>
                            <div className="box--shop--items">
                                <Row>
                                    <Col md={4} xs="auto">
                                        <div className="item-content-box">
                                            <div className="item-image">
                                                <img src={dress2} alt="dress2"/>
                                            </div>
                                            <div className="info--shop">
                                                <div className="product-item--color">
                                                    <div className="item--color active" id="item--color--1"
                                                         style={{backgroundColor: "#BFBFBF"}}></div>
                                                    <div className="item--color" id="item--color--2"
                                                         style={{backgroundColor: "#CDAE0B"}}></div>
                                                    <div className="item--color" id="item--color--3"
                                                         style={{backgroundColor: "#A63333"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4} xs="auto">
                                        <div className="item-content-box">
                                            <div className="item-image">
                                                <img src={dress1} alt="dress1"/>
                                            </div>
                                            <div className="info--shop">
                                                <div className="product-item--color">
                                                    <div className="item--color" id="item--color--1"
                                                         style={{backgroundColor: "#BFBFBF"}}></div>
                                                    <div className="item--color active" id="item--color--2"
                                                         style={{backgroundColor: "#CDAE0B"}}></div>
                                                    <div className="item--color" id="item--color--3"
                                                         style={{backgroundColor: "#A63333"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4} xs="auto">
                                        <div className="item-content-box">
                                            <div className="item-image">
                                                <img src={dress3} alt="dress3"/>
                                            </div>
                                            <div className="info--shop">
                                                <div className="product-item--color">
                                                    <div className="item--color active" id="item--color--1"
                                                         style={{backgroundColor: "#BFBFBF"}}></div>
                                                    <div className="item--color" id="item--color--2"
                                                         style={{backgroundColor: "#CDAE0B"}}></div>
                                                    <div className="item--color" id="item--color--3"
                                                         style={{backgroundColor: "#A63333"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} xs="auto">
                                        <div className="item-content-box">
                                            <div className="item-image">
                                                <img src={dress4} alt="dress2"/>
                                            </div>
                                            <div className="info--shop">
                                                <div className="product-item--color">
                                                    <div className="item--color" id="item--color--1"
                                                         style={{backgroundColor: "#BFBFBF"}}></div>
                                                    <div className="item--color" id="item--color--2"
                                                         style={{backgroundColor: "#CDAE0B"}}></div>
                                                    <div className="item--color active" id="item--color--3"
                                                         style={{backgroundColor: "#A63333"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4} xs="auto">
                                        <div className="item-content-box">
                                            <div className="item-image">
                                                <img src={dress5} alt="dress1"/>
                                            </div>
                                            <div className="info--shop">
                                                <div className="product-item--color">
                                                    <div className="item--color" id="item--color--1"
                                                         style={{backgroundColor: "#BFBFBF"}}></div>
                                                    <div className="item--color active" id="item--color--2"
                                                         style={{backgroundColor: "#CDAE0B"}}></div>
                                                    <div className="item--color" id="item--color--3"
                                                         style={{backgroundColor: "#A63333"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4} xs="auto">
                                        <div className="item-content-box">
                                            <div className="item-image">
                                                <img src={dress6} alt="dress3"/>
                                            </div>
                                            <div className="info--shop">
                                                <div className="product-item--color">
                                                    <div className="item--color  active" id="item--color--1"
                                                         style={{backgroundColor: "#BFBFBF"}}></div>
                                                    <div className="item--color" id="item--color--2"
                                                         style={{backgroundColor: "#CDAE0B"}}></div>
                                                    <div className="item--color" id="item--color--3"
                                                         style={{backgroundColor: "#A63333"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}