import React, {useState, useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Slide from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../../assets/css/styles/slider.css";

import slideA from "../../../assets/images/3.jpg";
import slide1A from "../../../assets/images/4.jpg";
import slide2A from "../../../assets/images/01.jpg";
import slide3A from "../../../assets/images/02.jpg";
import slide4A from "../../../assets/images/03.jpg";
import slide5A from "../../../assets/images/04.jpg";

export default function Slider() {

    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        fade: true,
        autoplay: true,
        speed: 8000,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <div>
                        <Slide {...settings}>
                            <div>
                                <img src={slideA} />
                            </div>
                            <div>
                                <img src={slide1A} />
                            </div>
                            <div>
                                <img src={slide2A} />
                            </div>
                            <div>
                                <img src={slide3A} />
                            </div>
                            <div>
                                <img src={slide4A} />
                            </div>
                            <div>
                                <img src={slide5A} />
                            </div>
                        </Slide>

                    </div>
                </Col>
            </Row>
        </>
    )
}