import React from "react";
import Slider from "../slider";
import Shop from "../shopEn";
import Collection from "../collection";
import Video from "../video";


export default function Main() {

    return (
        <>
            <Slider />
            <Shop />
            <Collection />
            <Video />
        </>
    );
}