import React, {useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "../../../assets/css/styles/bio.css";

import Me from "../../../assets/images/bio/me.jpg";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

export default function Fall() {

    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        fade: true,
        autoplay: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="bio__tab">
                            <Tabs>
                                <TabList>
                                    <Tab>About me</Tab>
                                    <Tab>Sarv Bio</Tab>
                                </TabList>

                                <TabPanel>
                                    <Row>
                                        <div className="biography--content--box">
                                            <div className="bio--content">
                                                <Row>
                                                    <Col md={4}>
                                                        <div className="intro--image">
                                                            <img src={Me} alt="GolSarv"/>
                                                        </div>
                                                    </Col>
                                                    <Col md={8}>
                                                        <div className="intro--box">
                                                            <p>
                                                                You can call me ‘GolSarv’. This is my stage name. I was
                                                                born in Iran in 1991. Despite my interest in
                                                                architecture, I completed my academic studies in the
                                                                field of IT engineering at the bachelor’s level. One of
                                                                my hobbies was surfing the Internet and following the
                                                                latest work of different brands, and seeing the clothes
                                                                that celebrities wore to different events. But pursuing
                                                                fashion and becoming interested in it as a career in the
                                                                final years of university was completely accidental for
                                                                me. At first, the challenges ahead, difficulties and the
                                                                lack of educational facilities discouraged me from
                                                                starting, but seeing a series about a hard-working
                                                                Japanese designer during World War II revived my hope
                                                                and enthusiasm for starting.

                                                            </p>
                                                            <p>
                                                                In the fall of 2015, my last year of university, I
                                                                enrolled in a Tailoring Introductory Course and a year
                                                                later completing it, I participated in the Introductory
                                                                and Advanced Clothing Design Coursed at an independent
                                                                institution. I completed the introductory course in the
                                                                fall of 2018 and the advanced course in the summer of
                                                                2020. While I was taking the preliminary course of
                                                                clothing design, I got acquainted with the Italian
                                                                international competition “A Design Award &
                                                                Competition”.
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <p>
                                                            It was interesting for me to try myself in
                                                            an international competition, so I decided on the failed
                                                            plan I had designed to participate in an internal
                                                            competition; redesign and enter it in an international
                                                            competition. At the same time, I enrolled in an
                                                            embroidery course so that I could perform a type of
                                                            Iranian embroidery (Sermeh-Doozi) on clothes.
                                                        </p>
                                                        <p>
                                                            The time was very limited, as well as the handwork on dress
                                                            was elegant and detailed. But to my disbelief, this design
                                                            got a high score in the initial judging, and finally I
                                                            managed to receive an Iron medal in this international
                                                            competition. This was the most exciting event of my
                                                            professional career in those years since I was new and a
                                                            novice to this field.
                                                        </p>
                                                        <p>
                                                            Until the design courses were over, I was working as
                                                            self-employed tailor and for one year I also designed and
                                                            sewed uniforms for several elementary and middle schools. At
                                                            first, I decided to work as a clothing designer for someone
                                                            else after completing the design, sewing, and embroidery
                                                            coursed and gain a lot of experience in this field, but
                                                            because I lived in a small town and the field of clothing
                                                            design in Iran is very new, as well as it was full of
                                                            weaknesses. Therefore, the farther you live from the
                                                            capital, finding a job as fashion designer is almost
                                                            difficult. And finally, I couldn’t find a job that I liked,
                                                            and as a result, I decided to start my own small business so
                                                            that I could do what I imagined in my mind and make it a
                                                            reality. This is how I created ‘Sarv’.
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Row>
                                </TabPanel>
                                <TabPanel>
                                    <Row>
                                        <div className="biography--content--box">
                                            <div className="bio--content">
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="intro--box">
                                                            <p>
                                                                <b>The meaning of “Sarv”: </b>
                                                                Cypress, whose Persian name is “Sarv” in Iran, is the
                                                                name of a noble and stout tree that is thousands of
                                                                years old. It was very valuable among the ancient
                                                                Iranians, and it was used as a motif in many works of
                                                                art. It is a symbol of resistance, freedom, and life; it
                                                                bends but never breaks. It seems that paisley
                                                                (Boteh-Jegheh) is a bent cypress, reminiscent of Iran,
                                                                which has been subjected to unlove and attacks by
                                                                foreigners and bent over the centuries. Paisley is the
                                                                most famous Iranian motif that is widely used in carpet
                                                                and fabric design. It seems that a free and steadfast
                                                                Iranian spirit has been breathed into this motif.
                                                                According to Zoroastrians, cypress was planted by order
                                                                of Zoroaster (Iranian prophet) in the nearest fire
                                                                temple of Kashmar, Khorasan, and therefore it is sacred
                                                                and valuable. With these interpretations, paisley, as a
                                                                symbol of Sarv and the main motif, creates the identity
                                                                of this brand. And considering the age, authenticity,
                                                                and meaningful concepts of the cypress was named as an
                                                                authentic Iranian name for an Iranian brand, “Sarv”.
                                                            </p>
                                                            <p>
                                                                <b>Date of Establishment: </b>
                                                                “Sarv” was born on October 21,
                                                                2021 in Iran, a small town belonging to Mazandaran
                                                                Province.
                                                            </p>
                                                            <p>
                                                                <b>The Identity of “Sarv”: </b>
                                                                “Sarv” is a classic brand, timeless and belongs to all
                                                                eras. The purpose of this brand is to combine and create
                                                                traditional-modern clothes. It inspires originality,
                                                                boldness, strength and at the same time maintaining
                                                                feminine charms for women who are not concerned with
                                                                passing fashions and are looking for quality,
                                                                durability, and authenticity beyond that.
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Row>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}