import React, {useState, useEffect} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactPlayer from 'react-player/file';
import Slide from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../../../assets/css/styles/slider.css";
import "../../../../assets/css/styles/collection.css";
import 'react-tabs/style/react-tabs.css';
import "../../../../assets/css/styles/tab.css";
import "../../../../assets/css/styles/video.css";

import Look1A from "../../../../assets/images/collections/spring/look1A.jpg";
import Look1B from "../../../../assets/images/collections/spring/look1B.jpg";
import Look2A from "../../../../assets/images/collections/spring/look2A.jpg";
import Look2B from "../../../../assets/images/collections/spring/look2B.jpg";
import Look3A from "../../../../assets/images/collections/spring/look3A.jpg";
import Look3B from "../../../../assets/images/collections/spring/look3B.jpg";
import Look3C from "../../../../assets/images/collections/spring/look3C.jpg";
import Look4A from "../../../../assets/images/collections/spring/look4A.jpg";
import Look4B from "../../../../assets/images/collections/spring/look4B.jpg";
import Look5A from "../../../../assets/images/collections/spring/look5A.jpg";
import Look5B from "../../../../assets/images/collections/spring/look5B.jpg";
import Look6A from "../../../../assets/images/collections/spring/look6A.jpg";
import Look6B from "../../../../assets/images/collections/spring/look6B.jpg";
import Look6C from "../../../../assets/images/collections/spring/look6C.jpg";
import Look7A from "../../../../assets/images/collections/spring/look7A.jpg";
import Look7B from "../../../../assets/images/collections/spring/look7B.jpg";
import Look7C from "../../../../assets/images/collections/spring/look7C.jpg";
import Look8A from "../../../../assets/images/collections/spring/look8A.jpg";
import Look8B from "../../../../assets/images/collections/spring/look8B.jpg";
import Look9A from "../../../../assets/images/collections/spring/look9A.jpg";
import Look9B from "../../../../assets/images/collections/spring/look9B.jpg";
import Look9C from "../../../../assets/images/collections/spring/look9C.jpg";
import Look10A from "../../../../assets/images/collections/spring/look10A.jpg";
import Look10B from "../../../../assets/images/collections/spring/look10B.jpg";


import M1 from "../../../../assets/images/collections/mdboard/spring/1.jpg";
import M2 from "../../../../assets/images/collections/mdboard/spring/2.jpg";
import M3 from "../../../../assets/images/collections/mdboard/spring/3.jpg";
import M4 from "../../../../assets/images/collections/mdboard/spring/4.jpg";
import M5 from "../../../../assets/images/collections/mdboard/spring/5.jpg";
import M6 from "../../../../assets/images/collections/mdboard/spring/6.jpg";
import M7 from "../../../../assets/images/collections/mdboard/spring/7.jpg";
import M8 from "../../../../assets/images/collections/mdboard/spring/8.jpg";
import M9 from "../../../../assets/images/collections/mdboard/spring/9.jpg";
import M10 from "../../../../assets/images/collections/mdboard/spring/10.jpg";
import M11 from "../../../../assets/images/collections/mdboard/spring/11.jpg";
import M12 from "../../../../assets/images/collections/mdboard/spring/12.jpg";
import M13 from "../../../../assets/images/collections/mdboard/spring/13.jpg";
import M14 from "../../../../assets/images/collections/mdboard/spring/14.jpg";
import M15 from "../../../../assets/images/collections/mdboard/spring/15.jpg";
import M16 from "../../../../assets/images/collections/mdboard/spring/16.jpg";
import M18 from "../../../../assets/images/collections/mdboard/spring/18.jpg";
import M19 from "../../../../assets/images/collections/mdboard/spring/19.jpg";
import M20 from "../../../../assets/images/collections/mdboard/spring/20.jpg";
import M21 from "../../../../assets/images/collections/mdboard/spring/21.jpg";
import M22 from "../../../../assets/images/collections/mdboard/spring/22.jpg";
import M23 from "../../../../assets/images/collections/mdboard/spring/23.jpg";
import M24 from "../../../../assets/images/collections/mdboard/spring/24.jpg";

export default function Spring() {
    var settings = {
        vertical: true,
        // variableWidth: true,
        dots: false,
        arrows: true,
        infinite: true,
        fade: true,
        autoplay: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    return (
        <>
            <Container>
                <Row>
                    <div className="collection-category-content">
                        <div className="collection-cate-box">
                            <Row>
                                <Col md={12}>
                                    <div className="collection__tab">
                                        <Tabs>
                                            <TabList>
                                                <Tab>statement</Tab>
                                                <Tab>images</Tab>
                                            </TabList>

                                            <TabPanel>
                                                <Container>
                                                    <Row>
                                                        <Col md={7}>
                                                            <div className="collection--statement--box">
                                                                <p>
                                                                    The collection “The Sun Rises from the East”
                                                                    promises hope. With the start of the covid-19
                                                                    epidemic, fashion had entered a new and challenging
                                                                    phase. Fashion lovers could not wear different
                                                                    clothes like in the past by participating in
                                                                    different events. But with the passage of time and
                                                                    being under control, life took on a new color. Hope
                                                                    became an inspiring word for the designer to create
                                                                    her new collection. According to the designer, war
                                                                    and women are key theme in which to look for hope.
                                                                    The looks change from dark to light and then they
                                                                    followed more warm and happy colors. While the light
                                                                    starts from the lower parts of the body (legs/first
                                                                    look) which symbolized the root and resistance and
                                                                    moves towards the middle of the body. Finally, in
                                                                    the third look, the bud of hope grows in the middle
                                                                    of the all-white coat. At first, the models wore
                                                                    masks with different images, which gives the meaning
                                                                    of censorship of women in Iran. The first image of
                                                                    the mask is the image of the first Iranian women’s
                                                                    protest against the mandatory hijab, which the
                                                                    designer added the photos of the two young girls to
                                                                    these protests. These two girls were killed by the
                                                                    men of the family in recent years due to family
                                                                    differences and wrong culture and traditions. The
                                                                    roots of resistance in the second Look are depicted
                                                                    on a black coat with ribbon embroidery in the shape
                                                                    of roots, and the third Look shows hope and light.
                                                                    And finally, these women put aside the censorship
                                                                    and try to be themselves and restore their rights.
                                                                </p>
                                                                <p>
                                                                    The designer tried to pay attention to details and
                                                                    use elements, such as the sun, buds, roots and
                                                                    light, colors that remind us of despair, hope,
                                                                    strength, energy, and life. It starts from the east
                                                                    to show the collection that is a symbol of hope.
                                                                </p>
                                                            </div>
                                                        </Col>
                                                        <Col md={5}>
                                                            <div
                                                                className="collection--statement--mood spring--collection">
                                                                <h5 className="mood--board--title">Mood Board</h5>
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={M1}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M2}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M3}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M4}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M5}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M6}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M7}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M8}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M9}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M10}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M11}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M12}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M13}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M14}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M15}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M16}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M18}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M19}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M20}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M21}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M22}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M23}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={M24}/>
                                                                    </div>
                                                                </Slide>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <div
                                                                className="collection--statement--box statement--second--box">
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="collection--video">
                                                    <Row className="justify-content-md-center">
                                                        <Col md={12}>
                                                            <div className="video--content">
                                                                <div className='player-wrapper'>
                                                                    <ReactPlayer
                                                                        className='react-player'
                                                                        url="video/Spring-Summer-2022.mp4"
                                                                        width='40%'
                                                                        height='40%'
                                                                        controls={true}
                                                                        muted={true}
                                                                        playing={false}
                                                                        loop={false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="collection--inner--cate spring--inner--slider">
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look1A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look1B}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look1</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look2A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look2B}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look2</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look3A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look3B}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look3C}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look3</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look4A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look4B}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look4</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look5A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look5B}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look5</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look6A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look6B}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look6C}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look6</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>
                                                                    <div>
                                                                        <img src={Look7A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look7B}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look7C}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look7</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look8A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look8B}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look8</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>

                                                                    <div>
                                                                        <img src={Look9A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look9B}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look9C}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look9</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="collection--images--box">
                                                                <Slide {...settings}>
                                                                    <div>
                                                                        <img src={Look10A}/>
                                                                    </div>
                                                                    <div>
                                                                        <img src={Look10B}/>
                                                                    </div>
                                                                </Slide>
                                                                <div className="collection--images--name">
                                                                    <h5>look10</h5>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    )
}