import './App.css';
import React, {useEffect} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import Navbar from "./components/navbarEn";
import Main from "./components/pages/main";
import Biography from "./components/pages/biography/";
import Collections from "./components/pages/collections/";
import FallWinter from "./components/pages/collections/fall";
import Spring from "./components/pages/collections/spring";
import Spring2023 from "./components/pages/collections/spring2023";
import Footer from "./components/pages/footerEn";
import ScrollToTop from "./components/scroll";

function App() {
    return (
        <Router>
            <ScrollToTop>
                <Navbar/>
                <Routes>
                    <Route path="/" exact element={<Main />} />
                    <Route path="/biography" exact element={<Biography />} />
                    <Route path="/collections" exact element={<Collections />} />
                    <Route path="/fall-winter" exact element={<FallWinter />} />
                    <Route path="/spring" exact element={<Spring />} />
                    <Route path="/spring2023" exact element={<Spring2023 />} />
                </Routes>
                <Footer />
            </ScrollToTop>
        </Router>
    );
}

export default App;
