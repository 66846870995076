import React from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactPlayer from 'react-player/file';

import "../../../assets/css/styles/video.css";

export default function Video() {

    return (
        <>
            <Container fluid>
                <div className="sarv--video">
                    <Row className="justify-content-md-center">
                        <Col md={12}>
                            <div className="video--content">
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player'
                                        url="video/Main.mp4"
                                        width='90%'
                                        height='90%'
                                        controls={true}
                                        muted={true}
                                        playing={true}
                                        loop={true}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}